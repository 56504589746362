export default function getEntityData(path, props) {
  const data = analyticsDataFnsByPath[path]?.(props) || {}
  // overwrite null values when present, otherwise retain null
  return { slug: null, entity_id: null, entity_type: null, ...data }
}

const analyticsDataFnsByPath = {
  '/aedition/[slug]': p => ({
    entity_type: 'contentful',
    entity_id: p?.article?.id,
    slug: p?.article?.slug,
  }),
  '/provider/[slug]': p => ({
    entity_type: 'provider',
    entity_id: p?.provider?.id,
    slug: p?.provider?.slug,
  }),
  '/concern/[slug]': p => ({
    entity_type: 'concern',
    entity_id: p?.concern?.id,
    slug: p?.concern?.slug,
  }),
  '/procedure/[slug]': p => ({
    entity_type: 'procedure',
    entity_id: p?.procedure?.id,
    slug: p?.procedure?.slug,
  }),
  '/procedure/[slug]/cost': p => ({
    entity_type: 'procedure_costs',
    entity_id: p?.procedure?.id,
    slug: `${p?.procedure?.slug}/cost`,
  }),
  '/procedure/[slug]/recovery': p => ({
    entity_type: 'procedure_recovery',
    entity_id: p?.procedure?.id,
    slug: `${p?.procedure?.slug}/recovery`,
  }),
}
