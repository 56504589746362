import React, { useCallback, useEffect, useState } from 'react'
import classnames from 'classnames'
import dynamic from 'next/dynamic'
import { toastError } from 'services/util/error'
import NotificationContext from 'services/notifications/context'
const PromptModal = dynamic(() => import('services/notifications/components/prompt_modal'))
import styles from './styles.scss'

const PERMISSION_STATES = Object.freeze({
  DEFAULT: 'default',
  GRANTED: 'granted',
  DENIED: 'denied',
})

const BLOCKED =
  'AEDIT is blocked from delivering notifications. You can remove the block from your address bar.'
const REFUSED = 'You have chosen not to receive notifications.'

const NotificationManager = props => {
  const { children } = props

  const [globalNotif, setGlobalNotif] = useState(undefined)
  useEffect(() => {
    if (Boolean(window?.['Notification'])) {
      setGlobalNotif(() => window.Notification)
    }
  }, [])
  const permission = globalNotif?.['permission']

  const [promptModalOpen, setPromptOpen] = useState(false)
  const [promptContent, setPromptContent] = useState({
    body: 'With your permission, we can notify you when your consultation is about to start.',
    onPromptAccepted: () => Promise.resolve(),
    onPromptRejected: () => Promise.reject(new Error('Prompt Rejected')),
    onRequestClose: () => setPromptOpen(false),
  })
  const [awaitingPermissions, setAwaitingPermissions] = useState(false)

  const triggerPermissionFlow = useCallback(
    requestedBody =>
      new Promise((resolve, reject) => {
        if (!globalNotif) {
          return reject(new Error('Requested too early. Global Notification not yet available.'))
        }
        if (permission === PERMISSION_STATES.GRANTED) {
          return resolve()
        }
        if (permission === PERMISSION_STATES.DENIED) {
          return reject(new Error(BLOCKED))
        }
        setPromptContent({
          body: requestedBody,
          onPromptAccepted: () => {
            setAwaitingPermissions(true)
            setPromptOpen(false)
            return resolve()
          },
          onPromptRejected: () => {
            setPromptOpen(false)
            setAwaitingPermissions(false)
            return reject(new Error(REFUSED))
          },
          onRequestClose: () => {
            setPromptOpen(false)
            setAwaitingPermissions(false)
            return reject(new Error(REFUSED))
          },
        })
        setPromptOpen(true)
      })
        .then(() => globalNotif.requestPermission())
        .catch(toastError)
        .finally(() => {
          setAwaitingPermissions(false)
        }),
    [globalNotif, permission, setPromptContent, setPromptOpen]
  )

  return (
    <NotificationContext.Provider value={{ permission, triggerPermissionFlow }}>
      {children}

      <div
        className={classnames('notification-await-permission-overlay', {
          visible: awaitingPermissions,
        })}
      />
      <PromptModal isOpen={promptModalOpen} showCloseButton={false} {...promptContent} />
      <style jsx>{styles}</style>
    </NotificationContext.Provider>
  )
}

export default NotificationManager
