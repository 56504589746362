import { useEffect, createContext, useContext, useCallback, useState } from 'react'

const getViewport = () => {
  window['_viewport'] = window['_viewport'] || {}
  return window['_viewport']
}

function queueTarget(target, callback) {
  const viewport = getViewport()
  viewport.queue = viewport.queue || []
  viewport.queue.push([target, callback])
}

function addTarget(target, callback) {
  const viewport = getViewport()
  if (viewport.observer) {
    viewport.observer.observe(target)
    viewport.callbacks.set(target, callback)
  } else {
    queueTarget(target, callback)
  }
}

function removeTarget(target) {
  const viewport = getViewport()
  viewport.observer.unobserve(target)
  viewport.callbacks.delete(target)
}

const contextValue = {
  addTarget,
  removeTarget,
}

const ViewportContext = createContext(contextValue)

function executeCallbacks(entries) {
  const viewport = getViewport()
  entries.forEach(({ isIntersecting, target }) => {
    if (isIntersecting && viewport.callbacks.has(target)) {
      viewport.callbacks.get(target)()
    }
  })
}

export const ViewportProvider = ({ children }) => {
  useEffect(() => {
    if ('IntersectionObserver' in window) {
      const viewport = getViewport()
      viewport.observer = new IntersectionObserver(executeCallbacks)
      viewport.callbacks = new Map()
      if (viewport.queue) {
        viewport.queue.forEach(([t, cb]) => addTarget(t, cb))
        delete viewport.queue
      }
    }
  }, [])
  return <ViewportContext.Provider value={contextValue}>{children}</ViewportContext.Provider>
}

const useViewportTracker = (callback, removeOnFire = true) => {
  const [target, setTarget] = useState(null)
  const ref = useCallback(element => element && setTarget(element), [])
  const { addTarget, removeTarget } = useContext(ViewportContext)

  useEffect(() => {
    if (target) {
      removeOnFire
        ? addTarget(target, () => {
            callback()
            removeTarget(target)
          })
        : addTarget(target, callback)

      return () => removeTarget(target)
    }
  }, [target])

  return ref
}

export default useViewportTracker
