import useViewportTracker from 'hooks/use_viewport_tracker'
import dynamic from 'next/dynamic'
import { useEffect, useState } from 'react'
import Div100vh from 'components/Div100Vh'
import { Track } from 'services/analytics'
import styles from './popup.scss'

const OpenInAppOrBrowser = dynamic(() => import('./open-in-app-or-browser'))
const AppBanner = dynamic(() => import('./app-banner'))

export default function AppPopup({
  onStayInBrowserClick,
  copy = {},
  visit = 'first',
  analyticsData = {},
}) {
  // used for CSS transition
  const [loaded, setLoaded] = useState(false)
  const withLoaded = klass => `${klass}${loaded ? ' --loaded' : ''}`
  useEffect(() => {
    setTimeout(() => setLoaded(true), 100)
  }, [])

  const handleStayInBrowserClick = () => {
    setLoaded(false)
    // timeout allows us to css transition out
    setTimeout(() => {
      if (typeof onStayInBrowserClick === 'function') {
        onStayInBrowserClick()
      }
    }, 500)
    Track.openInAppClicked({ choice: 'browser', ...analyticsData, type: visit })
  }
  const handleOpenInAppClick = () => {
    Track.openInAppClicked({ choice: 'app', ...analyticsData, type: visit })
  }

  const ref = useViewportTracker(() => Track.openInAppLoaded({ ...analyticsData, type: visit }))
  const modifier = visit === 'first' ? '--tray' : '--banner'
  return (
    <Div100vh
      // intercom is an obnoxious z-index of 2147483003, so we make the app popup 2147483003 + 1
      className={`app-popup__overlay fixed bottom-0 right-0 left-0 w-screen overflow-hidden z-[2147483004] ${modifier}`}>
      <div className={withLoaded('app-popup__overlay-background')} />
      <div className={withLoaded('app-popup__content')} ref={ref}>
        {visit === 'first' ? (
          <OpenInAppOrBrowser
            copy={copy?.firstVisit}
            handleStayInBrowserClick={handleStayInBrowserClick}
            handleOpenInAppClick={handleOpenInAppClick}
          />
        ) : (
          <AppBanner
            copy={copy?.secondVisit}
            dismiss={handleStayInBrowserClick}
            handleOpenInAppClick={handleOpenInAppClick}
          />
        )}
      </div>
      <style jsx>{styles}</style>
    </Div100vh>
  )
}
