import React, { useEffect, useState } from 'react'
import dynamic from 'next/dynamic'
import Head from 'next/head'
import Script from 'next/experimental-script'
import NiceModal from '@ebay/nice-modal-react'
import withError from 'next-with-error'
import { FlagSmithState } from 'services/flagsmith/state'
const AuthModal = dynamic(() => import('components/authentication/modal'))
import ErrorBoundary from 'components/error_boundary'
import Navigation from 'components/navigation'
import { GlobalWrapper, getFavorites } from 'components/global'
import IntercomBlacklist from 'components/intercom_blacklist'
import AccessibleFocusOutlineElement from 'services/accessibility'
import NotificationManager from 'services/notifications/components/manager'
import ErrorPage from './404'
import { ViewportProvider } from 'hooks/use_viewport_tracker'
import { WebVitalsProvider } from 'hooks/use_web_vitals'
import { IntercomSuppressionProvider } from 'hooks/use_hide_intercom/provider'
import { initClientSideLogdna } from 'services/logger'
import { trackPageview, GA_TRACKING_ID } from 'services/google-analytics'
import { ContentfulConfigProvider } from 'state/contentful-config'
import { IconProvider } from 'components/icon/provider'
import AppPopup from 'components/app_popup/'

import styles from 'styles/base.scss'
import fortAwesomeFont from 'styles/fonts/fort-awesome.scss'
import googleFonts from 'styles/fonts/google-fonts.scss'
import tailwind from 'styles/tailwind.scss'

const SENTRY_DSN = process.env.SENTRY_DSN || null

const MyApp = props => {
  const {
    Component,
    router,
    pageProps,
    initialUser,
    loginCopy,
    contentfulConfig,
    flagsmithConfig,
    findAProviderProps,
    favorites,
    config,
  } = props
  const [currentUser, setCurrentUser] = useState(initialUser)
  const asPath = router.asPath?.toLowerCase() ?? ''
  const refCode = router.query?.ref_id ?? ''

  // Store values coming from SSR in the client to avoid repeat calls and undefined values
  const [flagsConfig, _setFlagsConfig] = useState(false)
  const [flagsLoaded, _setFlagsLoaded] = useState(false)

  const [findAProviderLoaded, _setFindAProviderLoaded] = useState(false)
  const [findAProvider, _setFindAProvider] = useState(false)

  useEffect(() => {
    const handleRouteChange = url => trackPageview(url)

    router.events.on('routeChangeComplete', handleRouteChange)

    return () => {
      router.events.off('routeChangeComplete', handleRouteChange)
    }
  }, [router.events])

  if (!flagsLoaded) {
    _setFlagsLoaded(true)
    _setFlagsConfig(flagsmithConfig)
  }

  if (!findAProviderLoaded) {
    _setFindAProviderLoaded(true)
    _setFindAProvider(findAProviderProps)
  }

  // Utilize when flagsmith webhooks are implemented
  // useEffect(() => {
  //   flagsmith.onChange = () => {
  //     _setFlagsConfig(flagsmith.getConfig())
  //   }

  //   return () => {}
  // }, [])

  // setup Branch
  useEffect(() => {
    const setup = async () => {
      const { setupBranch } = await import('services/branch')
      setupBranch()
    }

    setup()
  }, [])

  // setup logDNA browser if not in dev
  useEffect(() => {
    initClientSideLogdna()
  }, [])

  // set up Sentry if not in dev
  useEffect(() => {
    const setup = async () => {
      const { init } = await import('@sentry/browser')

      if (!(process.env.NODE_ENV === 'development')) {
        init({
          dsn: SENTRY_DSN,
          environment: `node_env-${process.env.ENV_SETTINGS}`,
          release: process.env.CIRCLE_HASH,
          beforeSend(event) {
            const isNonErrorException = event.exception.values[0].value.startsWith(
              'Non-Error exception captured'
            )

            if (isNonErrorException) {
              return null
            }
            return event
          },
        })
      }
    }

    setup()
  }, [])

  // set access headers
  useEffect(() => {
    // getInitialProps are not invoked on pages with getServerSideProps
    authenticate({ asPath }).then(setCurrentUser)
  }, [asPath])

  // set ReactModal's appElement for a11y compliance
  // see: https://reactcommunity.org/react-modal/accessibility/#app-element
  useEffect(() => {
    const setup = async () => {
      const { default: ReactModal } = await import('react-modal')
      ReactModal.setAppElement(document.getElementById('__next'))
    }

    setup()
  }, [])

  // register service worker
  useEffect(() => {
    const setup = async () => {
      // delay the registration by 5 seconds
      if (navigator.serviceWorker) {
        const { onIdle } = await import('services/util/abstract')
        const registerSW = () => navigator.serviceWorker.register('/service-worker.js')
        const after = 5 * 1000 // ms
        onIdle(registerSW, { after })
      }
    }

    setup()
  }, [])

  useEffect(() => {
    if (!window) return

    const name =
      currentUser?.first_name && currentUser?.last_name
        ? `${currentUser.first_name} ${currentUser.last_name}`
        : ''

    window.intercomSettings = {
      api_base: 'https://api-iam.intercom.io',
      app_id: 'lf3f9pf7',
      ...(currentUser
        ? {
            name,
            email: currentUser.email,
            user_id: currentUser.id,
          }
        : {}),
    }
  }, [currentUser])

  return (
    <>
      <Head>
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <meta charSet="UTF-8" key="charset" />

        <script
          dangerouslySetInnerHTML={{
            __html: `
              window.dataLayer = window.dataLayer || [];
              function gtag(){dataLayer.push(arguments);}
              gtag('js', new Date());
              gtag('config', '${GA_TRACKING_ID}', {
                page_path: window.location.pathname,
              });
            `,
          }}
        />
        <script
          dangerouslySetInnerHTML={{
            __html: `(function(){var w=window;var ic=w.Intercom;if(typeof ic==="function"){ic('reattach_activator');ic('update',w.intercomSettings);}else{var d=document;var i=function(){i.c(arguments);};i.q=[];i.c=function(args){i.q.push(args);};w.Intercom=i;var l=function(){var s=d.createElement('script');s.type='text/javascript';s.async=true;s.src='https://widget.intercom.io/widget/lf3f9pf7';var x=d.getElementsByTagName('script')[0];x.parentNode.insertBefore(s,x);};if(document.readyState==='complete'){l();}else if(w.attachEvent){w.attachEvent('onload',l);}else{w.addEventListener('load',l,false);}}})();`,
          }}
        />
      </Head>
      <GlobalWrapper
        currentUser={currentUser}
        refCode={refCode}
        loginCopy={loginCopy}
        findAProvider={findAProvider}
        favorites={favorites}
        config={config}>
        <IntercomSuppressionProvider>
          <IconProvider>
            <div className="parallax">
              <ErrorBoundary>
                <ViewportProvider>
                  <FlagSmithState config={flagsConfig} currentUser={currentUser}>
                    <NiceModal.Provider>
                      <ContentfulConfigProvider config={contentfulConfig}>
                        <NotificationManager>
                          <style jsx global>
                            {styles}
                          </style>
                          <style jsx global>
                            {fortAwesomeFont}
                          </style>
                          <style jsx global>
                            {googleFonts}
                          </style>
                          <style jsx global>
                            {tailwind}
                          </style>

                          <AccessibleFocusOutlineElement>
                            <AuthModal />
                            {/* Nav component is here instead of Layout because of re-rendering implications */}
                            <Navigation />
                            <ErrorBoundary>
                              <WebVitalsProvider>
                                <Script
                                  strategy="afterInteractive"
                                  src={`https://googletagmanager.com/gtag/js?id=${GA_TRACKING_ID}`}
                                />
                                <Component {...pageProps} />
                                <AppPopup pageProps={pageProps} path={router.pathname} />
                              </WebVitalsProvider>
                            </ErrorBoundary>
                          </AccessibleFocusOutlineElement>
                        </NotificationManager>
                      </ContentfulConfigProvider>
                      <IntercomBlacklist />
                    </NiceModal.Provider>
                  </FlagSmithState>
                </ViewportProvider>
              </ErrorBoundary>
            </div>
          </IconProvider>
        </IntercomSuppressionProvider>
      </GlobalWrapper>
    </>
  )
}

MyApp.getInitialProps = async params => {
  const { Component, ctx } = params

  const initialUser = await authenticate(ctx)

  // flagsmith
  let flagsmithConfig
  if (typeof window === 'undefined') {
    const { default: flagsmith } = await import('flagsmith-nodejs')
    const { getUser } = await import('services/flagsmith/user')

    await flagsmith.init({
      environmentID: process.env.FLAGSMITH,
      cacheFlags: false, // AsyncStorage works only in browser
      enableLogs: false,
      onError: async err => {
        const { trackError } = await import('services/util/error')
        await trackError(`flagsmith.init failed`, err)
      },
      onChange: (oldflags, params) => console.log('changing', { oldflags, params }),
    })

    const { key } = await getUser(initialUser, ctx)

    const { traits, flags } = await flagsmith.getUserIdentity(key)
    // manually mock the state we were passing in from isomorphic version
    flagsmithConfig = ctx.res.locals.flagsmithConfig = {
      environmentID: process.env.FLAGSMITH,
      flags,
      traits,
      identity: key,
    }
  }

  // seo
  let findAProviderProps
  if (typeof window === 'undefined') {
    const { getFindAProviderProps } = await import('services/seo')
    findAProviderProps = await getFindAProviderProps()
  }

  let pageProps = {}
  if (Component.getInitialProps) {
    pageProps = (await Component.getInitialProps(ctx)) ?? {}
  }

  const { contentful } = await import('services/contentful')
  const data = await contentful.getInitialAppData()

  const favorites = initialUser ? await getFavorites() : []

  const config = await getConfig()

  return {
    pageProps,
    initialUser,
    ...data,
    flagsmithConfig,
    findAProviderProps,
    config,
    favorites,
  }
}

async function getConfig() {
  const { callApi } = await import('services/config')

  let config
  try {
    config = await callApi('GET', '/config')
  } catch (err) {
    const { log } = await import('services/util/log')
    log('Failed to fetch config', null, { error: err })
  }

  return config ?? (await import('services/constants')).CONFIG
}

const authenticate = async ctx => {
  const { getCurrentUser, setAccessHeaders, isAuthenticated } = await import('services/auth')
  const { redirectTo } = await import('services/next')
  const { isAuthorized } = await import('services/util/auth')

  setAccessHeaders(ctx.req)

  let currentUser = null
  if (isAuthenticated(ctx.req)) {
    currentUser = await getCurrentUser(ctx.req, ctx.res)
  }

  if (ctx.res) {
    ctx.res.locals.currentUser = currentUser
  }

  if (!isAuthorized(ctx.asPath, currentUser)) {
    redirectTo('/', { res: ctx.res })
  }

  return currentUser
}

export default withError(ErrorPage)(MyApp)
