var __assign =
  (this && this.__assign) ||
  function () {
    __assign =
      Object.assign ||
      function (t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
          s = arguments[i]
          for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p)) t[p] = s[p]
        }
        return t
      }
    return __assign.apply(this, arguments)
  }
var __rest =
  (this && this.__rest) ||
  function (s, e) {
    var t = {}
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0) t[p] = s[p]
    if (s != null && typeof Object.getOwnPropertySymbols === 'function')
      for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
        if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
          t[p[i]] = s[p[i]]
      }
    return t
  }
import React, { forwardRef, useState, useEffect } from 'react'
var warned = false
var Div100vh = forwardRef(function (_a, ref) {
  var style = _a.style,
    other = __rest(_a, ['style'])
  var height = use100vh()
  // TODO: warn only in development
  if (!warned && (style === null || style === void 0 ? void 0 : style.height)) {
    warned = true
    console.warn('<Div100vh /> overrides the height property of the style prop')
  }
  var styleWithRealHeight = __assign(__assign({}, style), {
    height: height ? height + 'px' : '100vh',
  })
  return React.createElement('div', __assign({ ref: ref, style: styleWithRealHeight }, other))
})
Div100vh.displayName = 'Div100vh'
export default Div100vh
export function use100vh() {
  var _a = useState(measureHeight),
    height = _a[0],
    setHeight = _a[1]
  var wasRenderedOnClientAtLeastOnce = useWasRenderedOnClientAtLeastOnce()
  useEffect(
    function () {
      if (!wasRenderedOnClientAtLeastOnce) return
      function setMeasuredHeight() {
        var measuredHeight = measureHeight()
        setHeight(measuredHeight)
      }
      window.addEventListener('resize', setMeasuredHeight)
      return function () {
        return window.removeEventListener('resize', setMeasuredHeight)
      }
    },
    [wasRenderedOnClientAtLeastOnce]
  )
  return wasRenderedOnClientAtLeastOnce ? height : null
}
export function measureHeight() {
  var _a
  if (!isClient()) return null
  return (
    window.innerHeight ||
    ((_a = document.documentElement) === null || _a === void 0 ? void 0 : _a.clientHeight)
  )
}
// Once we ended up on the client, the first render must look the same as on
// the server so hydration happens without problems. _Then_ we immediately
// schedule a subsequent update and return the height measured on the client.
// It's not needed for CSR-only apps, but is critical for SSR.
function useWasRenderedOnClientAtLeastOnce() {
  var _a = useState(false),
    wasRenderedOnClientAtLeastOnce = _a[0],
    setWasRenderedOnClientAtLeastOnce = _a[1]
  useEffect(function () {
    if (isClient()) {
      setWasRenderedOnClientAtLeastOnce(true)
    }
  }, [])
  return wasRenderedOnClientAtLeastOnce
}
function isClient() {
  return typeof window !== 'undefined' && typeof document !== 'undefined'
}
