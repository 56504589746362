import React, { useState, useEffect } from 'react'
import dynamic from 'next/dynamic'
import Router from 'next/router'
import NProgress from 'nprogress'
import cn from 'classnames'
import AeditLink from 'services/link'
import { Track } from 'services/analytics'
import { trackError } from 'services/util/error'
import { db } from 'services/config'
import { AeditLogo } from 'components/logos'
import { Icon } from 'components/icon/Icon.js'
import { withGlobal } from 'components/global'
import useCurrentRoute from 'hooks/use_current_route'
import { Div } from 'styleguide/packages/components'

const MobileMenu = dynamic(() => import('./mobile'))
const SolutionsMenu = dynamic(() => import('./solutions'))
const AuthMenu = dynamic(() => import('./auth'))
const SearchMenu = dynamic(() => import('./search'))

NProgress.configure({
  trickleSpeed: 150, // default is 200
  showSpinner: false,
  minimum: 0.15,
})

const Navigation = props => {
  const { setGlobalState, procedureFilters, toggleAuth, currentUser } = props

  const dropdownMenus = Object.freeze({
    SOLUTIONS: 'solutions',
    SEARCH: 'search',
    AUTH: 'auth',
    CLOSED: null,
  })

  const [concerns, setConcerns] = useState([])
  const [procedures, setProcedures] = useState([])
  const [openMenu, setOpenMenu] = useState(dropdownMenus.CLOSED)
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false)

  useEffect(() => {
    if (mobileMenuOpen) {
      document.body.style.overflow = 'hidden'
    } else {
      document.body.style.overflow = ''
    }
  }, [mobileMenuOpen, openMenu])

  const closeAllMenus = () => setOpenMenu(dropdownMenus.CLOSED)

  const route = useCurrentRoute()
  const toggleCandidateAuth = () => {
    Track.candidateLoginSignupClicked()
    toggleAuth('login', {
      loginCopyLabel: route,
    })
  }

  const toggleProviderAuth = () => {
    Track.providerLoginClicked()
    toggleAuth('provider')
  }

  // fetch initial data
  useEffect(() => {
    let shouldResolve = true

    const fetchAnalytics = async entity_type => {
      try {
        return await db.get('/analytics/entity/views', {
          params: {
            entity_type,
            _limit: 6,
          },
        })
      } catch (error) {
        // request will fail if the user is using an ad-blocker
        return { error }
      }
    }

    Promise.all([fetchAnalytics('concern'), fetchAnalytics('procedure')]).then(
      ([concernRes, procedureRes]) => {
        if (concernRes.error) {
          trackError('Failed to fetch top concerns in NavigationV2', concernRes.error)
        }
        if (procedureRes.error) {
          trackError('Failed to fetch top procedures in NavigationV2', procedureRes.error)
        }

        const concs = concernRes?.data?.data?.map(c => c.analytics)
        const procs = procedureRes?.data?.data?.map(p => p.analytics)

        if (shouldResolve) {
          setConcerns(concs)
          setProcedures(procs)
        }
      }
    )

    return () => {
      shouldResolve = false
    }
  }, [])

  // setup progress bar
  useEffect(() => {
    Router.events.on('routeChangeStart', toPath => {
      NProgress.start()
      setMobileMenuOpen(false)

      // we want to clear out the global procedure search/filter state if we're not going
      // to a procedure detail or to the procedures search page.
      if (!toPath.includes('/procedure') && procedureFilters !== null) {
        setGlobalState({ procedureFilters: null })
      }
    })

    Router.events.on('routeChangeComplete', () => {
      NProgress.done()
      closeAllMenus()
    })

    return () => {
      Router.events.off('routeChangeStart')
      Router.events.off('routeChangeComplete')
    }
  }, [])

  return (
    <header className="header sticky bg-black top-0 right-0 left-0 h-11 px-6 z-40 md:h-16 lg:px-12">
      <div className="relative flex items-center justify-between max-w-screen-xl mx-auto text-white h-full md:justify-end lg:justify-between">
        <span className="md:hidden cursor-pointer">
          {openMenu === 'search' ? (
            <Icon name="chevron-left" onClick={() => setOpenMenu(null)} />
          ) : (
            <AeditLogo destination="/" color="white" />
          )}
        </span>

        <AeditLogo
          destination="/"
          color="white"
          className="cursor-pointer absolute left-0 hidden md:block lg:relative lg:order-2"
        />

        <ul className="hidden text-white h-full md:flex lg:order-1">
          <li
            className="flex items-center mr-8 cursor-pointer"
            onClick={() => setOpenMenu(dropdownMenus.SOLUTIONS)}
            onMouseEnter={() => setOpenMenu(dropdownMenus.SOLUTIONS)}
            onMouseLeave={closeAllMenus}>
            <span
              className={cn('flex t-overline-sm text-white animated-underline lg:t-overline', {
                'animated-underline-hover': openMenu === dropdownMenus.SOLUTIONS,
              })}>
              SOLUTIONS
            </span>
            <Icon
              name="chevron-down"
              stroke="white"
              className={cn('transition-all duration-500', {
                '-rotate-180': openMenu === dropdownMenus.SOLUTIONS,
              })}
            />
          </li>

          <li className="flex items-center">
            <AeditLink
              className="flex t-overline-sm text-white cursor-pointer animated-underline mr-8 lg:t-overline"
              pathname="/providers">
              FIND A PROVIDER
            </AeditLink>
          </li>

          <li className="flex items-center">
            <AeditLink
              pathname="/aedition"
              className="flex t-overline-sm text-white cursor-pointer animated-underline mr-8 lg:t-overline">
              AEDITION
            </AeditLink>
          </li>
        </ul>

        {/* Visible: tablet, desktop */}
        <ul className="hidden items-center h-full md:flex lg:order-3">
          <li className="mr-8">
            <Div
              className="flex t-overline-sm text-white cursor-pointer uppercase lg:t-overline"
              onClick={() => {
                Track.globalSearchClicked()

                setOpenMenu(
                  openMenu === dropdownMenus.SEARCH ? dropdownMenus.CLOSED : dropdownMenus.SEARCH
                )
              }}>
              <Icon name="search" stroke="white" />
            </Div>
          </li>

          {/* Visible: desktop only */}
          {!currentUser && (
            <li className="hidden lg:block">
              <button
                type="button"
                className="max-h-[32px] flex items-center text-white cursor-pointer uppercase rounded bg-green py-2 px-4 group mr-8 transition-all hover:bg-link"
                data-cy="open-auth-button"
                onClick={toggleCandidateAuth}>
                <p className="t-overline-sm lg:t-overline">I'm a Candidate</p>
              </button>
            </li>
          )}
          <li
            className="hidden h-full md:flex"
            onClick={() => setOpenMenu(dropdownMenus.AUTH)}
            onMouseEnter={() => setOpenMenu(dropdownMenus.AUTH)}
            onMouseLeave={closeAllMenus}>
            <div className="flex items-center t-overline-sm text-white cursor-pointer uppercase lg:t-overline">
              {currentUser ? (
                <>
                  <Icon name="person" stroke="white" className="" />
                  <p
                    className={cn('t-overline-sm ml-2 animated-underline lg:t-overline lg:block', {
                      'animated-underline-hover': openMenu === dropdownMenus.AUTH,
                    })}>
                    {currentUser.first_name}
                  </p>
                </>
              ) : (
                <>
                  <p
                    className={cn(
                      't-overline-sm hidden animated-underline lg:t-overline lg:block',
                      {
                        'animated-underline-hover': openMenu === dropdownMenus.AUTH,
                      }
                    )}>
                    I'm a Provider
                  </p>
                  <p
                    className={cn('t-overline-sm animated-underline lg:t-overline lg:hidden', {
                      'animated-underline-hover': openMenu === dropdownMenus.AUTH,
                    })}>
                    Log In / Sign Up
                  </p>
                </>
              )}
              <Icon
                name="chevron-down"
                stroke="white"
                className={cn('transition-all duration-500', {
                  '-rotate-180': openMenu === dropdownMenus.AUTH,
                })}
              />
            </div>

            <AuthMenu
              isShown={openMenu === dropdownMenus.AUTH}
              setShown={() => setOpenMenu(dropdownMenus.AUTH)}
              setClosed={closeAllMenus}
              currentUser={currentUser}
              toggleCandidateAuth={toggleCandidateAuth}
              toggleProviderAuth={toggleProviderAuth}
            />
          </li>
        </ul>

        <MobileMenu
          openMenu={openMenu}
          mobileMenuOpen={mobileMenuOpen}
          setMobileMenuOpen={setMobileMenuOpen}
          setSearchOpen={() => setOpenMenu(dropdownMenus.SEARCH)}
          setSearchClosed={closeAllMenus}
          currentUser={currentUser}
          toggleCandidateAuth={toggleCandidateAuth}
          toggleProviderAuth={toggleProviderAuth}
        />
      </div>

      <SearchMenu
        isShown={openMenu === dropdownMenus.SEARCH}
        setShown={() => setOpenMenu(dropdownMenus.SEARCH)}
        setClosed={closeAllMenus}
      />

      <SolutionsMenu
        isShown={openMenu === dropdownMenus.SOLUTIONS}
        setShown={() => setOpenMenu(dropdownMenus.SOLUTIONS)}
        setClosed={closeAllMenus}
        concerns={concerns}
        procedures={procedures}
      />
    </header>
  )
}

export default withGlobal(Navigation)
