let ClientSideLogdna = null
/*
 ** We only send logs to logdna on STAGING and PRODUCTION
 ** Development logs are not logged and only show on local
 **
 */
export const initClientSideLogdna = async user => {
  if (typeof window !== 'undefined' && process.env.ENV_SETTINGS !== 'development') {
    // No ssr yet, have to do a dynamic import: https://github.com/logdna/logdna-browser/pull/23
    const { LogDNABrowserLogger } = await import('@logdna/browser')
    ClientSideLogdna = new LogDNABrowserLogger()

    ClientSideLogdna.init(process.env.LOGDNA_API_KEY).addContext({
      env: `node_env-${process.env.ENV_SETTINGS}`,
      user,
    })
  }
}
