import React, { useEffect, useState } from 'react'
import { IntercomSuppressionContext } from './context'

export const IntercomSuppressionProvider = ({ children }) => {
  const [suppressors, setSuppressors] = useState([])

  const hasSuppressors = Boolean(suppressors.length)

  useEffect(() => {
    if (!window.Intercom) return

    if (hasSuppressors) {
      Intercom('update', { hide_default_launcher: true })
    } else {
      Intercom('update', { hide_default_launcher: false })
    }
  }, [hasSuppressors])

  const addSuppressor = id => setSuppressors(prev => [...prev, id])

  const removeSuppressor = id =>
    setSuppressors(prev => prev.filter(suppressor => suppressor !== id))

  return (
    <IntercomSuppressionContext.Provider value={{ addSuppressor, removeSuppressor }}>
      {children}
    </IntercomSuppressionContext.Provider>
  )
}
