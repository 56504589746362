import React, { useEffect, useState } from 'react'
import { useRouter } from 'next/router'
import { INTERCOM_ROUTE_BLACKLIST as blacklist } from 'services/constants'

const IntercomBlacklist = () => {
  const { pathname } = useRouter()

  const [hidden, setHidden] = useState(false)

  useEffect(() => {
    setHidden(blacklist.has(pathname))
  }, [pathname])

  return (
    <>
      {/* keep fragments to prevent console warning. styled jsx only works if it's *inside* the top level element */}
      <style jsx>{`
        .intercom-lightweight-app {
          ${hidden ? 'visibility: hidden;' : ''};
        }
      `}</style>
    </>
  )
}

export default IntercomBlacklist
