import React, { useState, useEffect } from 'react'
import { IconContext } from '../context'

const dir = 'https://s3.amazonaws.com/assets.aedit.com/website/icons/'

export const IconProvider = ({ children }) => {
  const [sheetsList, setSheetsList] = useState([])
  const [sheets, setSheets] = useState({})

  useEffect(() => {
    const batch = []
    sheetsList.forEach(sheet => {
      const data = sheets[sheet]
      if (!data) {
        batch.push(
          fetch(dir + sheet).then(async data => ({
            sheet,
            body: await data.text(),
          }))
        )
      }
    })

    Promise.allSettled(batch).then(results => {
      const newState = results.reduce(
        (s, { value: { sheet, body } }) => {
          s[sheet] = body
          return s
        },
        { ...sheets }
      )
      setSheets(newState)
    })
  }, [sheetsList])

  const addSheet = sheetName => {
    !sheetsList.includes(sheetName) && setSheetsList(s => [...s, sheetName])
  }
  const value = React.useMemo(() => ({ addSheet }), [sheets])

  return (
    <IconContext.Provider value={value}>
      {Object.entries(sheets).map(([sheet, data]) => (
        <div style={{ display: 'none' }} key={sheet} dangerouslySetInnerHTML={{ __html: data }} />
      ))}
      {children}
    </IconContext.Provider>
  )
}
