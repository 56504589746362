import React from 'react'

const ContentfulConfigContext = React.createContext()

export function ContentfulConfigProvider({ children, config }) {
  return (
    <ContentfulConfigContext.Provider value={config}>{children}</ContentfulConfigContext.Provider>
  )
}

export function useContentfulConfig(key) {
  const config = React.useContext(ContentfulConfigContext)
  return config[key]
}
