import { useState, useEffect, useContext } from 'react'
import { getCookie, setCookie } from 'services/cookie'
import { FlagSmithStateContext } from 'services/flagsmith/state'
import { useContentfulConfig } from 'state/contentful-config'
import AppPopup from './popup'
import { log } from 'services/util/log'
import { isIOS } from 'services'
import getEntityData from './get-entity-data'

const COOKIE_NAME = 'open_in_app_prompt'

// First visit: show user the full open in app/continue in browser tray
// Second visit: show user the app banner version
// Third visit and beyond: show nothing

function blockScroll() {
  document.getElementById('body')?.classList?.add('noscroll')
}
function enableScroll() {
  document.getElementById('body')?.classList?.remove('noscroll')
}

export default function AppPopupController({ pageProps, path }) {
  const { flags } = useContext(FlagSmithStateContext)
  const isEnabled = flags['app-popup']?.enabled
  const configuration = useContentfulConfig('app-popup')

  const [open, setOpen] = useState(false)
  const [visit, setVisit] = useState()

  const analyticsData = getEntityData(path, pageProps)

  useEffect(() => {
    if (!isEnabled) {
      return
    }

    const handle = async () => {
      if (typeof window !== 'undefined') {
        try {
          if (configuration?.hideList) {
            const currentPath = window.location.pathname
            if (await isMatch(currentPath, configuration.hideList)) {
              if (
                !configuration.hideExceptions ||
                !(await isMatch(currentPath, configuration.hideExceptions))
              ) {
                // if current path is on the configuration hide list and the configuration does not have an
                // exceptions list OR it does but the path isnt on the exception list, don't show the popup
                return
              }
            }
          }
        } catch (e) {
          log(
            'There was an error parsing the app-popup hideList and hideExceptions options. ' +
              'Ensure that each item is a non-empty string!',
            'error',
            { error: e, configuration }
          )
          return
        }

        if (isIOS()) {
          const currentVisit = getCookie(COOKIE_NAME) || 'first'

          if (currentVisit === 'first' || currentVisit === 'second') {
            setOpen(true)
            setVisit(currentVisit)
          }
          if (currentVisit === 'first') {
            blockScroll()
          }
        }
      }
    }

    handle()
  }, [])

  const handleStayInBrowserClick = () => {
    const newVisit = visit === 'first' ? 'second' : 'third'
    if (visit === 'first') {
      enableScroll()
    }
    setCookie(COOKIE_NAME, newVisit, null, { expires: 7 })
    setOpen(false)
  }

  if (!isEnabled) {
    return null
  }

  return open ? (
    <AppPopup
      analyticsData={analyticsData}
      onStayInBrowserClick={handleStayInBrowserClick}
      visit={visit}
      copy={configuration?.copy}
    />
  ) : null
}

const isMatch = async (str, patterns) => {
  const { isMatch } = await import('micromatch')
  return isMatch(str, patterns)
}
