/* eslint-disable no-console */
import React, { Component } from 'react'
import FallbackPage from 'features/errors/error'
import { inDevelopment } from 'services/env'
import { trackError } from 'services/util/error'

const defaultErrorBoundaryState = {
  hasCapturedError: false,
  capturedError: null,
}

export default class ErrorBoundary extends Component {
  constructor(props) {
    super(props)
    this.state = defaultErrorBoundaryState
  }

  componentDidCatch(error, info) {
    if (inDevelopment) {
      console.group("Not sending this error to sentry because we're in dev ↓")
      console.error(error)
      console.log('Extra info: %o', info)
      console.groupEnd()
      return
    }
    let msg = error?.message ?? 'Error page loaded'
    trackError(`Error boundary hit: ${msg}`, error, info)
  }

  static getDerivedStateFromError(error) {
    return { capturedError: error, hasCapturedError: true }
  }

  render() {
    const { children } = this.props
    const { capturedError, hasCapturedError } = this.state

    if (hasCapturedError) {
      return (
        <FallbackPage
          onLeavingErrorPage={() => this.setState(defaultErrorBoundaryState)}
          statusCode={capturedError?.statusCode || 500}
          skipLog={true}
        />
      )
    }

    return children
  }
}
