import React, { useState, useEffect } from 'react'

const AccessibleFocusOutlineElement = props => {
  const [enableOutline, _setEnableOutline] = useState(false)
  const { children, ...rest } = props

  const handleEvent = e => {
    const isTabEvent = e.keyCode === 9
    const mouseEvent = /mouse/i.test(e.type)

    if (isTabEvent) {
      _setEnableOutline(true)
    }

    if (mouseEvent) {
      _setEnableOutline(false)
    }
  }

  useEffect(() => {
    window.addEventListener('keydown', handleEvent)
    window.addEventListener('mousedown', handleEvent)

    return () => {
      window.removeEventListener('keydown', handleEvent)
      window.removeEventListener('mousedown', handleEvent)
    }
  }, [])

  return (
    <div
      id={'accessibility-wrapper'}
      className={`${enableOutline ? '' : 'no-outline-on-focus'}`}
      {...rest}>
      {children}
    </div>
  )
}

export default AccessibleFocusOutlineElement
